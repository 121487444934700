import React from 'react';
import { GoStarFill } from 'react-icons/go';

const Testimonial = ({ text, name }) => (
  <blockquote className="italic border-l-4 border-primary pl-4 mb-4 ml-8">
    <div className='flex flex-row text-yellow-400 space-x-1'>
      {[...Array(5)].map((_, i) => (
        <GoStarFill key={i} size={20} />
      ))}
    </div>
    {text}
    <br /><span className='font-semibold'>- {name}</span>
  </blockquote>
);

export default Testimonial;
