import React from 'react';
import { motion } from 'framer-motion';
import { ImWhatsapp } from "react-icons/im";
import { FiMail } from "react-icons/fi";
import { SlCallOut } from "react-icons/sl";
import { sanitizePhoneNumber } from '../utils/helpers';

const ContactOptions = ({ empresa, userName }) => {
  const siteUrl = window.location.href;

  return (
    <motion.div className="text-left p-4 border rounded-lg mx-4 shadow-md mb-6 text-sm" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <p className="mb-4 text-xs">Gracias por tu interés, {userName}. <br/>Si deseas más información, por favor contáctanos:</p>
      <button className="w-full p-2 mb-2 bg-green-600 text-white rounded hover:bg-green-700 text-left px-4">
        <a href={`mailto:${empresa.email}?subject=Información sobre bots de servicio&body=Hola, soy ${userName}. Me gustaría obtener más información sobre sus servicios. Mi URL actual es ${siteUrl}`}
          className='flex flex-row items-center justify-between'>
          <FiMail size={20} />
          <>Enviar un Email</>
        </a>
      </button>
      <button className="w-full p-2 mb-2 bg-green-600 text-white rounded hover:bg-green-700 text-left px-4">
        <a href={`tel:${empresa.telefono}`}
          className='flex flex-row items-center justify-between'>
          <SlCallOut size={20} />
          Llamar
        </a>
      </button>
      <button className="w-full p-2 mb-2 bg-green-600 text-white rounded hover:bg-green-700 text-left px-4">
        <a href={`https://wa.me/${sanitizePhoneNumber(empresa.telefono)}?text=Hola, soy ${userName}. Me gustaría obtener más información sobre sus servicios. Mi URL actual es ${siteUrl}`}
          className='flex flex-row items-center justify-between'>
          <ImWhatsapp size={20} />
          Inicar Chat
        </a>
      </button>
    </motion.div>
  );
};

export default ContactOptions;
