import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaBuilding, FaMapMarkerAlt, FaUserTie, FaBullseye, FaEye, FaHeart } from 'react-icons/fa';
import CallToAction from '../components/CallToAction';

const Empresa = () => {
  const [empresa, setEmpresa] = useState(null);

  useEffect(() => {
    fetch('/data/empresa.json')
      .then(response => response.json())
      .then(data => setEmpresa(data));
  }, []);

  if (!empresa) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="container mx-auto my-8 p-4 bg-white dark:bg-gray-800 rounded shadow-lg">
      <Helmet>
        <title>Sobre Nosotros | {empresa.nombre}</title>
        <meta name="description" content={`Conoce más sobre ${empresa.nombre}`} />
      </Helmet>
      <div className='lg:px-24 lg:py-12'>
        <h2 className="text-3xl font-semibold mb-8 text-center text-primary">Sobre Nosotros</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
          <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4 text-primary flex items-center">
              <FaBuilding className="mr-2" /> {empresa.nombre}
            </h3>
            <p className="mb-4">{empresa.descripcion}</p>
          </div>
          <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4 text-primary flex items-center">
              <FaMapMarkerAlt className="mr-2" /> Dirección
            </h3>
            <p className="mb-2"><strong>Dirección:</strong> {empresa.direccion}</p>
            <p className="mb-2"><strong>Teléfono:</strong> <a href={`tel:${empresa.telefono}`} className="text-accent hover:underline">{empresa.telefono}</a></p>
            <p className="mb-2"><strong>Email:</strong> <a href={`mailto:${empresa.email}`} className="text-accent hover:underline">{empresa.email}</a></p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4 text-primary flex items-center">
              <FaBullseye className="mr-2" /> Misión
            </h3>
            <p>{empresa.mision}</p>
          </div>
          <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4 text-primary flex items-center">
              <FaEye className="mr-2" /> Visión
            </h3>
            <p>{empresa.vision}</p>
          </div>
          <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4 text-primary flex items-center">
              <FaHeart className="mr-2" /> Valores
            </h3>
            <ul className="list-disc list-inside">
              {empresa.valores.map((valor, index) => (
                <li key={index} className="mb-2">{valor}</li>
              ))}
            </ul>
          </div>
        </div>

        <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md mb-8">
          <h3 className="text-2xl font-semibold mb-4 text-primary flex items-center">
            <FaUserTie className="mr-2" /> Fundador
          </h3>
          <p className="mb-2"><strong>Nombre:</strong> {empresa.fundador.nombre}</p>
          <p><strong>Experiencia:</strong> {empresa.fundador.experiencia}</p>
        </div>
      </div>

      <CallToAction />

    </div>
  );
}

export default Empresa;
