import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { useCart } from '../context/CartContext';

const CartItems = ({ items }) => {
  const { removeFromCart } = useCart();

  return (
    <div className="mb-4">
      <h3 className="text-xl font-semibold mb-2">Planes Seleccionados</h3>
      <ul>
        {items.map((item, index) => (
          <li key={index} className="mb-2 flex justify-between items-center border-primary border-b border-t shadow px-2 py-2">
            <span>
              <strong>{item.name}:</strong> {item.features} - <strong>{item.price}</strong>
            </span>
            <button onClick={() => removeFromCart(index)} className="text-red-600 hover:text-red-800 pl-4">
              <FaTrash />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CartItems;
