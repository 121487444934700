import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : { items: [], userInfo: {} };
  });

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (item) => {
    setCart(prevCart => ({ ...prevCart, items: [...prevCart.items, item] }));
  };

  const removeFromCart = (index) => {
    setCart(prevCart => {
      const newItems = prevCart.items.filter((_, i) => i !== index);
      return { ...prevCart, items: newItems };
    });
  };

  const updateUserInfo = (userInfo) => {
    setCart(prevCart => ({ ...prevCart, userInfo }));
  };

  const clearCart = () => {
    setCart({ items: [], userInfo: {} });
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, updateUserInfo, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
