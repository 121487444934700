import React from 'react';
import { motion } from 'framer-motion';

const Options = ({ availableOptions, handleOptionClick }) => (
  <motion.div className="text-left pl-8" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
    {/* <p className="mb-2">Selecciona una opción:</p> */}
    {availableOptions.map((option, index) => (
      <button
        key={option.id}
        onClick={() => handleOptionClick(option.id - 1)}
        className="w-full p-2 mb-2 bg-blue-600 text-white rounded hover:bg-blue-700 text-left"
      >
        <div className='flex flex-row space-x-2 text-sm'>
          <div className='font-extrabold'>{option.id}.</div>
          <div>{option.question}</div>
        </div>
         
      </button>
    ))}
  </motion.div>
);

export default Options;
