import React from 'react';

const CartSummary = ({ totalPrice }) => (
  <div className="mb-4">
    <h3 className="text-xl font-semibold mb-2">Resumen de la Compra</h3>
    <p><strong>Total:</strong> USD ${totalPrice}</p>
  </div>
);

export default CartSummary;
