import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CallToAction from '../components/CallToAction';

const BlogPost = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    fetch('/data/posts.json')
      .then(response => response.json())
      .then(data => {
        const post = data.find(p => p.id === parseInt(id));
        setPost(post);
      });
  }, [id]);

  if (!post) {
    return <div>Publicación no encontrada</div>;
  }

  return (
    <div className="container mx-auto my-8 p-0 bg-white dark:bg-gray-800 rounded shadow-lg">
      <Helmet>
        <title>{post.title} | Bot de Servicio al Cliente</title>
        <meta name="description" content={post.excerpt} />
      </Helmet>
      <img src={post.image} alt={post.title} className="w-full h-64 object-cover rounded-t-lg" />
      <div className='p-6 lg:px-24 lg:py-12'>
        <h2 className="text-3xl text-primary font-semibold mt-4">{post.title}</h2>
        <div className="mt-4 p-4" dangerouslySetInnerHTML={{ __html: post.content }}></div>
      <CallToAction />
      </div>
    </div>
  );
}

export default BlogPost;
