import React from 'react';
import { FaEnvelope } from 'react-icons/fa';

const ContactInfo = ({ empresa }) => (
  <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md">
    <h3 className="text-2xl font-semibold mb-4 text-primary flex items-center">
      <FaEnvelope className="mr-2" /> Contacto
    </h3>
    <p className="mb-2"><strong>Dirección:</strong> {empresa.direccion}</p>
    <p className="mb-2"><strong>Teléfono:</strong> <a href={`tel:${empresa.telefono}`} className="text-accent hover:underline">{empresa.telefono}</a></p>
    <p className="mb-2"><strong>Email:</strong> <a href={`mailto:${empresa.email}`} className="text-accent hover:underline">{empresa.email}</a></p>
  </div>
);

export default ContactInfo;
