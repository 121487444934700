import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CallToAction from '../components/CallToAction';

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch('/data/posts.json')
      .then(response => response.json())
      .then(data => setPosts(data));
  }, []);

  return (
    <div className="container mx-auto my-8 p-4 bg-white dark:bg-gray-800 rounded shadow-lg">
      <Helmet>
        <title>Blog | Bot de Servicio al Cliente</title>
        <meta name="description" content="Artículos y recursos sobre bots de servicio al cliente." />
      </Helmet>
      <div className='lg:px-24 lg:py-12'>
        <h2 className="text-3xl font-semibold mb-8 text-center text-primary">Blog</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          {posts.map(post => (
            <div key={post.id} className="bg-gray-50 dark:bg-gray-700 p-6 rounded-lg shadow-lg transition-transform transform hover:-translate-y-2 hover:shadow-xl">
              <img src={post.image} alt={post.title} className="w-full h-56 object-cover rounded mb-4" />
              <h3 className="text-2xl font-semibold mb-2">{post.title}</h3>
              <p className="text-gray-700 dark:text-gray-300 mb-4">{post.excerpt}</p>
              <Link to={`/blog/${post.id}`} className="bg-accent text-white py-2 px-4 rounded hover:bg-yellow-600">Leer más</Link>
            </div>
          ))}
        </div>
        <CallToAction />
      </div>
    </div>
  );
}

export default Blog;
