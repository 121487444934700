import React, { useState, useEffect, useRef } from 'react';
import { FaBullseye, FaPaperPlane, FaTrash } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Message from './Message';
import Options from './Options';
import ContactOptions from './ContactOptions';
import { getGreeting } from '../utils/helpers';
import { TbMessageChatbot } from "react-icons/tb";

const ChatBot = () => {
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem('chatMessages');
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [input, setInput] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(() => {
    const savedOptions = localStorage.getItem('selectedOptions');
    return savedOptions ? JSON.parse(savedOptions) : [];
  });
  const [showContactOption, setShowContactOption] = useState(false);
  const [empresa, setEmpresa] = useState(null);
  const [faq, setFaq] = useState([]);
  const [userName, setUserName] = useState('');
  const [isAskingName, setIsAskingName] = useState(true);
  const chatBoxRef = useRef(null);

  useEffect(() => {
    fetch('/data/empresa.json')
      .then((response) => response.json())
      .then((data) => setEmpresa(data));

    fetch('/data/faq_bot.json')
      .then((response) => response.json())
      .then((data) => setFaq(data));
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (messages.length === 0) {
      const greetingMessage = { role: 'bot', content: `${getGreeting()}! Bienvenido a nuestro servicio de atención al cliente. ¿Cómo te llamas?` };
      setMessages([greetingMessage]);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
    localStorage.setItem('selectedOptions', JSON.stringify(selectedOptions));
  }, [messages, selectedOptions]);

  const sendMessage = (e) => {
    e.preventDefault();

    if (!input.trim()) return;

    const userMessage = { role: 'user', content: input };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    setInput('');

    if (isAskingName) {
      setUserName(input);
      const botMessage = { role: 'bot', content: `¡Encantado de conocerte, ${input}! ¿En qué puedo ayudarte hoy? Selecciona una opción:` };
      setMessages([...updatedMessages, botMessage]);
      setIsAskingName(false);
    } else {
      const optionIndex = parseInt(input) - 1;
      if (optionIndex >= 0 && optionIndex < faq.length && !selectedOptions.includes(optionIndex)) {
        const botMessage = { role: 'bot', content: faq[optionIndex].answer };
        setMessages([...updatedMessages, botMessage]);
        setSelectedOptions([...selectedOptions, optionIndex]);

        if (selectedOptions.length === faq.length - 1) {
          setShowContactOption(true);
        }
      } else {
        const botMessage = {
          role: 'bot',
          content: 'Lo siento, no tengo una respuesta para esa opción. Por favor elige una opción válida:',
        };
        setMessages([...updatedMessages, botMessage]);
      }
    }
  };

  const handleOptionClick = (index) => {
    const userMessage = { role: 'user', content: (index + 1).toString() };
    const updatedMessages = [...messages, userMessage];

    const botMessage = faq[index] ? { role: 'bot', content: faq[index].answer } : { role: 'bot', content: 'Lo siento, no tengo una respuesta para esa opción.' };
    setMessages([...updatedMessages, botMessage]);
    setSelectedOptions([...selectedOptions, index]);

    if (selectedOptions.length === faq.length - 1) {
      setShowContactOption(true);
    }
  };

  const availableOptions = faq.filter((_, index) => !selectedOptions.includes(index));

  const resetChat = () => {
    setMessages([]);
    setSelectedOptions([]);
    setUserName('');
    setIsAskingName(true);
    setShowContactOption(false);
    localStorage.removeItem('chatMessages');
    localStorage.removeItem('selectedOptions');
    window.location.reload(); // Recargar el componente
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white dark:bg-gray-800 rounded-2xl shadow-lg border" style={{ height: '90vh', display: 'flex', flexDirection: 'column' }}>
      <div className='flex items-center justify-center pb-4 text-gray-400'>
        <FaBullseye size={20} />
      </div>
      <h2 className="text-xl font-semibold mb-4 text-center text-primary flex flex-row items-center space-x-2 border-b px-4 py-2">
        <TbMessageChatbot size={30} />
        <a>ChatBot FAQ <span className='pl-2 font-light text-sm text-gray-500'>Demo ChatBot </span></a>
      </h2>
      <motion.div ref={chatBoxRef} className="chat-box flex-grow overflow-y-auto mb-2 p-2 bg-gray-100 dark:bg-gray-700 rounded-lg" style={{ height: 'calc(100vh - 200px)' }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
        {messages.map((message, index) => (
          <Message key={index} message={message} />
        ))}
        {!isAskingName && !showContactOption && availableOptions.length > 0 && (
          <Options availableOptions={availableOptions} handleOptionClick={handleOptionClick} />
        )}
        {showContactOption && empresa && (
          <ContactOptions empresa={empresa} userName={userName} />
        )}
      </motion.div>
      <form onSubmit={sendMessage} className="flex">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={isAskingName ? "Escribe tu nombre..." : "Escribe el número de tu pregunta..."}
          className="flex-grow px-4 py-2 rounded-l-lg bg-gray-200 dark:bg-gray-600 text-black dark:text-white focus:outline-none"
        />
        <button type="submit" className="py-2 px-3 bg-blue-600 text-white rounded-r-lg hover:bg-blue-700">
          <FaPaperPlane />
        </button>
      </form>
      <button onClick={resetChat} className="mt-6 mb-3 p-2 w-48 mx-auto border border-red-400 text-red-600 rounded hover:bg-red-50 flex items-center justify-center shadow">
        <FaTrash className="mr-2" /> Reiniciar Chat
      </button>
    </div>
  );
};

export default ChatBot;
