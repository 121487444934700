import React, { useState, useEffect } from 'react';
import { useCart } from '../context/CartContext';
import CartItems from '../components/CartItems';
import CartSummary from '../components/CartSummary';
import UserInfoForm from '../components/UserInfoForm';
import ServiceConditions from '../components/ServiceConditions';
import CartActions from '../components/CartActions';
import { toast } from 'react-toastify';

const Cart = () => {
  const { cart, updateUserInfo } = useCart();
  const [userInfo, setUserInfo] = useState(cart.userInfo);
  const [empresa, setEmpresa] = useState(null);

  useEffect(() => {
    fetch('/data/empresa.json')
      .then(response => response.json())
      .then(data => setEmpresa(data));
  }, []);

  useEffect(() => {
    updateUserInfo(userInfo);
  }, [userInfo, updateUserInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const resetUserInfo = () => {
    setUserInfo({
      fullName: '',
      company: '',
      phone: '',
      email: '',
      address: ''
    });
  };

  const getTotalPrice = () => {
    const prices = cart.items.map(item => parseFloat(item.price.replace(/[^0-9.-]+/g, "")));
    return prices.reduce((total, price) => total + price, 0).toFixed(2);
  };

  const handlePayment = () => {
    if (!userInfo.fullName || !userInfo.email || !userInfo.phone || !userInfo.address) {
      toast.error('Por favor completa todos los campos antes de continuar.');
      return;
    }
    toast.info('Redirigiendo a PayPal para procesar el pago...');

    const items = cart.items.map(item => ({
      name: item.name,
      price: item.price.replace('USD $', ''),
      currency: 'USD',
      quantity: 1
    }));

    const paypalURL = `https://www.paypal.com/cgi-bin/webscr?cmd=_cart&business=${empresa.paypal_email}&upload=1&currency_code=USD`;

    let itemIndex = 1;
    items.forEach(item => {
      paypalURL += `&item_name_${itemIndex}=${encodeURIComponent(item.name)}`;
      paypalURL += `&amount_${itemIndex}=${item.price}`;
      paypalURL += `&quantity_${itemIndex}=${item.quantity}`;
      itemIndex++;
    });

    paypalURL += `&custom=${encodeURIComponent(JSON.stringify(userInfo))}`;

    window.location.href = paypalURL;
  };

  const handleWhatsApp = () => {
    if (!userInfo.fullName || !userInfo.email || !userInfo.phone || !userInfo.address) {
      toast.error('Por favor completa todos los campos antes de continuar.');
      return;
    }
    const message = encodeURIComponent(`Hola, quiero comprar los siguientes productos:\n\n${cart.items.map(item => `- ${item.name}: ${item.price}\n`).join('')}\nTotal: USD $${getTotalPrice()}\n\nMis datos de contacto son:\nNombre: ${userInfo.fullName}\nEmpresa: ${userInfo.company}\nTeléfono: ${userInfo.phone}\nEmail: ${userInfo.email}\nDirección: ${userInfo.address}`);
    const phoneNumber = empresa.telefono.replace(/[^\d]/g, ''); 
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <div className="container mx-auto my-8 p-4 bg-white dark:bg-gray-800 rounded shadow-lg grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div className='lg:px-12 lg:py-8'>
        <h2 className="text-2xl font-semibold mb-4">Carrito de Compras</h2>
        <CartItems items={cart.items} />
        <CartSummary totalPrice={getTotalPrice()} />
        <UserInfoForm userInfo={userInfo} handleChange={handleChange} />
        <CartActions handlePayment={handlePayment} handleWhatsApp={handleWhatsApp} resetUserInfo={resetUserInfo} />
      </div>
      <ServiceConditions />
    </div>
  );
};

export default Cart;
