import React, { useEffect, useState } from 'react';

const Footer = () => {
  const [empresa, setEmpresa] = useState(null);

  useEffect(() => {
    fetch('/data/empresa.json')
      .then(response => response.json())
      .then(data => setEmpresa(data));
  }, []);

  if (!empresa) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Cargando...</div>;
  }

  return (
    <footer className="bg-primary text-white py-4">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-center md:text-left mb-4 md:mb-0">
            <p className="text-sm font-light">&copy; 2024 {empresa.nombre}. Todos los derechos reservados.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
