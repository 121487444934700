import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import CallToAction from '../components/CallToAction';

const FAQ = () => {
  const [faqs, setFaqs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    fetch('/data/faq.json')
      .then(response => response.json())
      .then(data => setFaqs(data));
  }, []);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="container mx-auto my-8 p-4 bg-white dark:bg-gray-800 rounded shadow-lg">
      <Helmet>
        <title>FAQ | Bot de Servicio al Cliente</title>
        <meta name="description" content="Preguntas frecuentes sobre nuestros bots de servicio al cliente." />
      </Helmet>
      <div className='lg:px-24 lg:py-12'>
        <h2 className="text-3xl font-semibold mb-8 text-center text-primary">Preguntas Frecuentes</h2>

        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div key={index} className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow-md">
              <div
                className="flex justify-between items-center cursor-pointer border-b pb-2"
                onClick={() => toggleFAQ(index)}
              >
                <h3 className="text-xl font-semibold text-primary">{faq.question}</h3>
                {activeIndex === index ? (
                  <FaChevronUp className="text-primary" />
                ) : (
                  <FaChevronDown className="text-primary" />
                )}
              </div>
              {activeIndex === index && (
                <div className="mt-4 text-gray-700 dark:text-gray-300 transition-all duration-300">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>

      <CallToAction />
      </div>
    </div>
  );
};

export default FAQ;
