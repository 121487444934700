import React from 'react';
import { motion } from 'framer-motion';

const Hero = ({ empresa }) => {
  return (
    <section className="relative bg-primary text-white text-center py-16 h-96 flex items-center justify-center" style={{ backgroundImage: "url('/images/post1.jpg')", backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="absolute inset-0 bg-primary opacity-50"></div> {/* Overlay */}
      <div className="relative container max-w-4xl mx-auto">
        <motion.h1 className="text-4xl font-bold mb-4" initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>{empresa.nombre}</motion.h1>
        <motion.p className="text-xl mb-8" initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.2 }}>{empresa.descripcion}</motion.p>
        <motion.a href="#beneficios" className="bg-accent text-white py-2 px-4 rounded hover:bg-yellow-600" initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.4 }}>
          Descubre Más
        </motion.a>
      </div>
    </section>
  );
}

export default Hero;
