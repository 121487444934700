import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBullseye, FaShareAlt, FaBars, FaTimes, FaShoppingCart } from 'react-icons/fa';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Cencomer.com',
        text: 'Conoce más sobre nuestra plataforma de servicio al cliente con bots avanzados.',
        url: window.location.href,
      }).catch((error) => console.log('Error al compartir:', error));
    } else {
      alert(`Comparte esta URL: ${window.location.href}`);
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-primary text-white p-4 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-2xl font-bold">Bot<span className='pl-4 text-lg font-light'>Cencomer.com</span></div>
        <div className="hidden lg:flex space-x-4">
          <Link to="/" className="hover:no-underline">Inicio</Link>
          <Link to="/empresa" className="hover:no-underline">Sobre Nosotros</Link>
          <Link to="/implementacion" className="hover:no-underline">Implementación</Link>
          <Link to="/precios" className="hover:no-underline">Precios</Link>
          <Link to="/contacto" className="hover:no-underline">Contacto</Link>
          <Link to="/faq" className="hover:no-underline">FAQ</Link>
          <Link to="/blog" className="hover:no-underline">Blog</Link>
          <Link to="/cart" className="hover:no-underline focus:outline-none flex items-center space-x-2 border-l border-blue-300 pl-3">
            <FaShoppingCart /> <span>Carrito</span>
          </Link>
          <button onClick={handleShare} className="hover:no-underline focus:outline-none flex items-center space-x-2 border-l border-blue-300 pl-3">
            <FaShareAlt className="inline-block mr-1" /> <p>Compartir</p>
          </button>
        </div>
        <div className="lg:hidden">
          <button onClick={toggleMobileMenu} className="focus:outline-none">
            {isMobileMenuOpen ? <FaTimes size={20} className='mr-4'/> : <FaBars size={20} className='mr-4'/>}
          </button>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="lg:hidden bg-primary">
          <Link to="/" className="block py-3 hover:no-underline border-b border-t mt-4 px-4" onClick={toggleMobileMenu}>Inicio</Link>
          <Link to="/empresa" className="block py-3 hover:no-underline border-b px-4" onClick={toggleMobileMenu}>Sobre Nosotros</Link>
          <Link to="/implementacion" className="block py-3 hover:no-underline border-b px-4" onClick={toggleMobileMenu}>Implementación</Link>
          <Link to="/precios" className="block py-3 hover:no-underline border-b px-4" onClick={toggleMobileMenu}>Precios</Link>
          <Link to="/contacto" className="block py-3 hover:no-underline border-b px-4" onClick={toggleMobileMenu}>Contacto</Link>
          <Link to="/faq" className="block py-3 hover:no-underline border-b px-4" onClick={toggleMobileMenu}>FAQ</Link>
          <Link to="/blog" className="block py-3 hover:no-underline border-b px-4" onClick={toggleMobileMenu}>Blog</Link>
          <Link to="/cart" className="block py-3 hover:no-underline border-b px-4" onClick={toggleMobileMenu}>Carrito</Link>
          <button onClick={() => { handleShare(); toggleMobileMenu(); }} className="block items-center w-full text-left py-3 focus:outline-none hover:no-underline border-b px-4">
            <FaShareAlt className="inline-block mr-1" /> Compartir
          </button>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
