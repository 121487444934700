import React from 'react';
import { FaWhatsapp, FaPaypal, FaTrash } from 'react-icons/fa';
import { useCart } from '../context/CartContext';

const CartActions = ({ handlePayment, handleWhatsApp, resetUserInfo }) => {
  const { clearCart } = useCart();

  const handleClearCart = () => {
    clearCart();
    resetUserInfo();
  };

  return (
    <div className="mt-4">
      <div className="flex flex-col lg:flex-row justify-between mt-4 space-y-2 lg:space-x-2 border-t border-b items-center py-4">
        <button
          onClick={handlePayment}
          className="flex items-center bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg transition-colors duration-300"
          style={{ backgroundColor: '#0070ba' }}
        >
          <FaPaypal className="mr-2" /> Pagar con PayPal
        </button>
        <button
          onClick={handleWhatsApp}
          className="flex items-center bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-lg transition-colors duration-300"
        >
          <FaWhatsapp className="mr-2" /> Enviar a WhatsApp
        </button>
      </div>
      <button
        onClick={handleClearCart}
        className="mt-4 flex items-center justify-center text-red-500 hover:text-red-700 transition-colors duration-300"
      >
        <FaTrash className="mr-2" /> Vaciar Carrito
      </button>
    </div>
  );
};

export default CartActions;
