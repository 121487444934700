import React from 'react';

const ServiceConditions = () => (
  <div className='lg:px-12 lg:py-8'>
    <h3 className="text-xl font-semibold mb-4 border-b pb-2 border-b-primary text-primary">Condiciones del Servicio</h3>
    <p className="mb-4 px-6">
      Al contratar nuestros servicios, usted acepta nuestras condiciones de uso. Aseguramos la implementación adecuada de nuestros bots y proporcionamos soporte continuo según el plan seleccionado.
    </p>
    <h3 className="text-xl font-semibold mb-4 border-b pb-2 border-b-primary text-primary">Proceso de Compra</h3>
    <p className="mb-4 px-6">
      1. Seleccione el plan que mejor se adapte a sus necesidades y agréguelo al carrito.
      <br />
      2. Proporcione su información de contacto y confirme su pedido.
      <br />
      3. Nuestro equipo se pondrá en contacto con usted para completar la implementación.
    </p>
  </div>
);

export default ServiceConditions;
