import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaCheckCircle, FaChartLine, FaCogs, FaWrench, FaRocket, FaEnvelope } from 'react-icons/fa';
import CallToAction from '../components/CallToAction';

const Implementation = () => {
  const [empresa, setEmpresa] = useState(null);

  useEffect(() => {
    fetch('/data/empresa.json')
      .then(response => response.json())
      .then(data => setEmpresa(data));
  }, []);

  if (!empresa) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="container mx-auto my-8 p-4 bg-white dark:bg-gray-800 rounded shadow-lg">
      <Helmet>
        <title>Implementación | Bot de Servicio al Cliente</title>
        <meta name="description" content="Conoce más sobre la implementación de nuestros bots de servicio al cliente" />
      </Helmet>
      <div className='lg:px-24 lg:py-12'>
        <h2 className="text-3xl font-semibold mb-4 text-center text-primary">Implementación</h2>
        <p className="mb-8 text-center text-lg">En {empresa.nombre}, ofrecemos una implementación completa de bots de servicio al cliente para mejorar la eficiencia y la satisfacción de tus clientes.</p>

        <h3 className="text-2xl font-semibold mb-4 text-primary">Proceso de Implementación</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
          <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md text-center">
            <FaChartLine className="text-4xl text-accent mb-4 mx-auto" />
            <h4 className="text-xl font-semibold mb-2">Análisis de Requisitos</h4>
            <p>Trabajamos contigo para entender tus necesidades y definir los requisitos del bot.</p>
          </div>
          <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md text-center">
            <FaCogs className="text-4xl text-accent mb-4 mx-auto" />
            <h4 className="text-xl font-semibold mb-2">Diseño y Desarrollo</h4>
            <p>Creamos el bot basado en los requisitos especificados, asegurando una experiencia de usuario óptima.</p>
          </div>
          <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md text-center">
            <FaWrench className="text-4xl text-accent mb-4 mx-auto" />
            <h4 className="text-xl font-semibold mb-2">Pruebas y Validación</h4>
            <p>Realizamos pruebas exhaustivas para garantizar que el bot funcione de manera eficiente y sin errores.</p>
          </div>
          <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md text-center">
            <FaRocket className="text-4xl text-accent mb-4 mx-auto" />
            <h4 className="text-xl font-semibold mb-2">Despliegue</h4>
            <p>Implementamos el bot en las plataformas elegidas, asegurando su correcto funcionamiento y disponibilidad.</p>
          </div>
          <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg shadow-md text-center">
            <FaCheckCircle className="text-4xl text-accent mb-4 mx-auto" />
            <h4 className="text-xl font-semibold mb-2">Mantenimiento y Optimización</h4>
            <p>Proporcionamos soporte continuo y realizamos mejoras basadas en el feedback de los usuarios y las necesidades cambiantes de tu negocio.</p>
          </div>
        </div>
      </div>
      <CallToAction />
    </div>
  );
}

export default Implementation;
