import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import ChatBot from '../components/ChatBot';
import Section from '../components/Section';
import Testimonial from '../components/Testimonial';
import CallToAction from '../components/CallToAction'; // Importar el nuevo componente

const Home = () => {
  const [empresa, setEmpresa] = useState(null);

  useEffect(() => {
    fetch('/data/empresa.json')
      .then(response => response.json())
      .then(data => setEmpresa(data));
  }, []);

  if (!empresa) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Cargando...</div>;
  }

  const testimonials = [
    { text: 'Este bot ha transformado nuestra atención al cliente. ¡Altamente recomendado!', name: 'Distribuidor Barranquilla' },
    { text: 'La implementación fue rápida y sin problemas. Nuestros clientes están encantados con la nueva atención.', name: 'Mayorista tecnológico' },
    { text: 'Gracias a este bot, hemos reducido el tiempo de respuesta y aumentado la satisfacción del cliente.', name: 'ORG' },
    { text: 'Un recurso invaluable para nuestro equipo de soporte. ¡Funciona a la perfección!', name: 'Soporte Pro' }
  ];

  return (
    <div className="dark:bg-gray-900 dark:text-gray-100">
      <Helmet>
        <title>Inicio | {empresa.nombre}</title>
        <meta name="description" content={`Conoce más sobre ${empresa.nombre}`} />
      </Helmet>

      <Hero empresa={empresa} />

      <div className="container mx-auto my-8 p-4 bg-white dark:bg-gray-800 rounded shadow-lg">
        <div className='flex flex-col lg:flex-row lg:space-x-8 items-start lg:pt-8 justify-between px-0 lg:px-24 border-b pb-8 mb-4'>
          <div>
            <Section id="beneficios" title="Beneficios del Bot de Servicio al Cliente">
              <ul className="list-disc list-inside space-y-2 pl-8">
                <li>Eficiencia Operativa</li>
                <li>Disponibilidad Ininterrumpida</li>
                <li>Mejora de la Experiencia del Cliente</li>
                <li>Incremento de Ventas</li>
              </ul>
            </Section>
            <Section id="caracteristicas" title="Características Destacadas">
              <ul className="list-disc list-inside space-y-2 pl-8">
                <li>Atención Personalizada</li>
                <li>Multicanal</li>
                <li>Fácil Integración</li>
              </ul>
            </Section>
            <Section id="testimonios" title="Testimonios">
              {testimonials.map((testimonial, index) => (
                <Testimonial key={index} text={testimonial.text} name={testimonial.name} />
              ))}
            </Section>
          </div>
          <div className='mb-12 lg:mb-0'>
            <ChatBot />
          </div>
        </div>
        <CallToAction /> {/* Agregar el componente de llamado a la acción */}
      </div>
    </div>
  );
}

export default Home;
