import React from 'react';

const UserInfoForm = ({ userInfo, handleChange }) => (
  <form>
    <h3 className="text-xl font-semibold mb-4">Información del Usuario</h3>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Nombre Completo</label>
      <input
        type="text"
        name="fullName"
        value={userInfo.fullName}
        onChange={handleChange}
        required
        className="mt-1 block w-full p-2 border rounded"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Empresa</label>
      <input
        type="text"
        name="company"
        value={userInfo.company}
        onChange={handleChange}
        className="mt-1 block w-full p-2 border rounded"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Teléfono</label>
      <input
        type="text"
        name="phone"
        value={userInfo.phone}
        onChange={handleChange}
        className="mt-1 block w-full p-2 border rounded"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Email</label>
      <input
        type="email"
        name="email"
        value={userInfo.email}
        onChange={handleChange}
        className="mt-1 block w-full p-2 border rounded"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Dirección</label>
      <input
        type="text"
        name="address"
        value={userInfo.address}
        onChange={handleChange}
        className="mt-1 block w-full p-2 border rounded"
      />
    </div>
  </form>
);

export default UserInfoForm;
