import React from 'react';
import { HiOutlineChat } from "react-icons/hi";
import { TbMessageChatbot } from "react-icons/tb";
import { motion } from 'framer-motion';

const Message = ({ message }) => (
  <motion.div
    className={`mb-4 text-sm flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    {message.role === 'bot' && (
      <div className="w-8 h-8 flex-shrink-0 mr-2">
        <TbMessageChatbot size={32} className="text-gray-500" />
      </div>
    )}
    <div
      className={`max-w-xs md:max-w-md px-4 py-2 rounded-lg shadow ${
        message.role === 'user'
          ? 'bg-blue-500 text-white self-end'
          : 'bg-gray-200 dark:bg-gray-600 text-black dark:text-white mr-8'
      }`}
    >
      {message.content}
    </div>
    {message.role === 'user' && (
      <div className="w-8 h-8 flex-shrink-0 ml-2">
        <HiOutlineChat size={32} className="text-blue-500" />
      </div>
    )}
  </motion.div>
);

export default Message;
