import React from 'react';
import PricingTable from '../components/PricingTable';

const plans = [
  { name: 'Básico', features: 'Interacción Básica, Soporte 24/7', price: 'USD $150 / mes' },
  { name: 'Profesional', features: 'Interacción Avanzada, Integración con CRM, Soporte Prioritario', price: 'USD $300 / mes' },
  { name: 'Empresarial', features: 'Interacción Personalizada, Integración Completa, Soporte Dedicado', price: 'USD $500 / mes' },
  { name: 'Implementación', features: 'Costo del proceso de Analisis e implementación', price: 'USD $200 (pago único)' }
];

const Prices = () => {
  return (
    <div className="container mx-auto my-8 p-4 lg:px-24 lg:py-12 bg-white dark:bg-gray-800 rounded shadow-lg">
      <h2 className="text-2xl font-semibold mb-4">Tabla de Tarifas</h2>
      <PricingTable plans={plans} />
    </div>
  );
};

export default Prices;
