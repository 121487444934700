import React from 'react';
import { Link } from 'react-router-dom';

const CallToAction = () => {
  return (
    <div className="bg-gradient-to-r from-blue-500 to-teal-400 text-white p-10 rounded-lg shadow-lg text-center my-8">
      <h2 className="text-3xl font-bold mb-6">Descubre nuestros planes y precios</h2>
      <p className="mb-6 text-lg">Encuentra el plan perfecto para tu empresa y mejora tu servicio al cliente con nuestros bots avanzados.</p>
      <Link 
        to="/precios" 
        className="bg-white text-blue-600 hover:bg-gray-200 py-3 px-6 rounded-lg text-lg font-semibold transition duration-300"
      >
        Ver Precios
      </Link>
    </div>
  );
}

export default CallToAction;
