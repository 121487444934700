import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ContactInfo from '../components/ContactInfo';
import CallToAction from '../components/CallToAction';

const Contact = () => {
  const [empresa, setEmpresa] = useState(null);
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [mensaje, setMensaje] = useState('');

  useEffect(() => {
    fetch('/data/empresa.json')
      .then((response) => response.json())
      .then((data) => setEmpresa(data));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (empresa) {
      const whatsappUrl = `https://wa.me/${empresa.telefono.replace(/[^\d]/g, '')}?text=${encodeURIComponent(
        `Hola, soy ${nombre} (${email}). ${mensaje}`
      )}`;
      window.open(whatsappUrl, '_blank');
    }
  };

  if (!empresa) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Cargando...</div>;
  }

  return (
    <div className="container mx-auto my-8 p-4 bg-white dark:bg-gray-800 rounded shadow-lg max-w-7xl">
      <Helmet>
        <title>Contacto | Bot de Servicio al Cliente</title>
        <meta name="description" content="Contáctanos para más información sobre nuestros bots de servicio al cliente." />
      </Helmet>
      <div className='flex flex-col lg:flex-row items-start lg:space-x-8'>
        <div className='w-full'>
          <h2 className="text-2xl font-semibold mb-4">Contáctanos</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Nombre</label>
              <input
                type="text"
                className="mt-1 block w-full p-2 border rounded"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Email</label>
              <input
                type="email"
                className="mt-1 block w-full p-2 border rounded"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Mensaje</label>
              <textarea
                className="mt-1 block w-full p-2 border rounded"
                rows="4"
                value={mensaje}
                onChange={(e) => setMensaje(e.target.value)}
                required
              ></textarea>
            </div>
            <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded">Enviar a WhatsApp</button>
          </form>
        </div>
        <div className='pt-12 w-full'>
          <ContactInfo empresa={empresa} /> {/* Agregar el componente ContactInfo y pasar los datos de la empresa */}
        </div>
      </div>
      <CallToAction />
    </div>

  );
};

export default Contact;
