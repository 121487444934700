import React from 'react';
import { useCart } from '../context/CartContext';
import { toast } from 'react-toastify';

const PricingTable = ({ plans }) => {
  const { addToCart } = useCart();

  const handleAddToCart = (plan) => {
    addToCart(plan);
    addToCart({
      name: 'Implementación',
      features: 'Costo del proceso de Analisis e implementación',
      price: 'USD $200 (pago único)',
    });
    toast.success(`${plan.name} agregado al carrito junto con el servicio de Implementación.`);
  };

  return (
    <>
      <div className="overflow-x-auto hidden lg:block">
        <table className="min-w-full bg-white dark:bg-gray-700 rounded-lg shadow">
          <thead>
            <tr>
              <th className="py-2 px-4 bg-primary dark:bg-blue-800 text-white">Plan</th>
              <th className="py-2 px-4 bg-primary dark:bg-blue-800 text-white">Características</th>
              <th className="py-2 px-4 bg-primary dark:bg-blue-800 text-white">Precio</th>
              <th className="py-2 px-4 bg-primary dark:bg-blue-800 text-white">Acción</th>
            </tr>
          </thead>
          <tbody>
            {plans.map((plan, index) => (
              <tr key={index} className="border-b">
                <td className="border px-4 py-2">{plan.name}</td>
                <td className="border px-4 py-2">{plan.features}</td>
                <td className="border px-4 py-2 font-extrabold">{plan.price}</td>
                <td className="border px-4 py-2">
                  <button
                    onClick={() => handleAddToCart(plan)}
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                  >
                    Agregar al Carrito
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="lg:hidden">
        {plans.map((plan, index) => (
          <div key={index} className="mb-4 bg-white dark:bg-gray-700 rounded-lg shadow p-4">
            <h3 className="text-xl font-semibold mb-2">{plan.name}</h3>
            <p className="mb-2"><strong>Características:</strong> {plan.features}</p>
            <p className="mb-2"><strong>Precio:</strong> {plan.price}</p>
            <button
              onClick={() => handleAddToCart(plan)}
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 w-full"
            >
              Agregar al Carrito
            </button>
          </div>
        ))}
      </div>
      <div className="mb-4 p-4 px-12 bg-white dark:bg-gray-700 rounded-lg shadow">
        <h3 className="text-xl font-semibold mb-2">Explicación de la Tabla de Tarifas</h3>
        <p className="mb-2">
          <strong>Plan Básico:</strong> El plan básico incluye interacción básica con el bot y soporte técnico las 24 horas del día, los 7 días de la semana. <strong>Precio:</strong> USD $150 al mes.
        </p>
        <p className="mb-2">
          <strong>Plan Profesional:</strong> El plan profesional ofrece interacción avanzada con el bot, integración con sistemas de CRM (Gestión de Relaciones con Clientes) y soporte técnico prioritario. <strong>Precio:</strong> USD $300 al mes.
        </p>
        <p className="mb-2">
          <strong>Plan Empresarial:</strong> El plan empresarial proporciona interacción personalizada con el bot, integración completa con todos los sistemas necesarios y soporte técnico dedicado. <strong>Precio:</strong> USD $500 al mes.
        </p>
        <p className="mb-2">
          <strong>Implementación:</strong> Este es el costo único para el análisis y la implementación inicial del bot en tu empresa. <strong>Precio:</strong> USD $200 (pago único).
        </p>
        <p className="mt-4">
          <strong>Resumen:</strong> Puedes elegir entre el plan Básico, Profesional o Empresarial, dependiendo de las necesidades y el tamaño de tu empresa. Además de los planes mensuales, hay un costo único para configurar e implementar el bot en tu sistema.
        </p>
      </div>
    </>
  );
};

export default PricingTable;
