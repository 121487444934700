import React from 'react';

const Section = ({ id, title, children }) => (
  <section id={id} className="mb-8">
    <h2 className="text-lg lg:text-3xl font-semibold mb-4 text-primary border-b p-3 rounded-lg shadow pl-6 lg:pl-6">{title}</h2>
    {children}
  </section>
);

export default Section;
