import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Implementation from './pages/Implementation';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Empresa from './pages/Empresa';
import Layout from './components/Layout';
import Cart from './pages/Cart';
import Prices from './pages/Prices'; // Importar la nueva página de Precios
import { CartProvider } from './context/CartContext';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  const [key, setKey] = useState(0);

  const resetChat = () => {
    setKey(prevKey => prevKey + 1); // Cambiar la clave para forzar el remount del componente
    localStorage.removeItem('chatMessages');
    localStorage.removeItem('selectedOptions');
  };

  return (
    <CartProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/implementacion" element={<Implementation />} />
            <Route path="/contacto" element={<Contact />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogPost />} />
            <Route path="/empresa" element={<Empresa />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/precios" element={<Prices />} /> {/* Añadir la ruta para Precios */}
          </Routes>
        </Layout>
        <ToastContainer />
      </Router>
    </CartProvider>
  );
}

export default App;
